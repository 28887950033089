<template>
  <div class="login">
    <Header />
    <div class="wrap">
      <div class="con">
        <div class="img">
          <img :src="require('@/assets/user/login.png')" alt="" />
        </div>
        <div class="user">
          <a-form id="formLogin" class="user-layout-login" :form="form" ref="formLogin" @submit="handleSubmit">
            <div class="container">
              <h3>登录</h3>
              <div class="line"></div>
              <a-form-item>
                <a-input class="cus-input" size="large" type="text" placeholder="请输入学生身份证号/报名号" v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入学生身份证号/报名号',
                      },
                    ],
                    validateTrigger: 'change',
                  },
                ]">
                  <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
                </a-input>
              </a-form-item>

              <a-form-item style="margin-bottom: 8px">
                <a-input-password class="cus-input" size="large" type="password" autocomplete="false"
                  placeholder="请输入密码" v-decorator="[
                    'password',
                    {
                      rules: [{ required: true, message: '请输入密码' }],
                      validateTrigger: 'blur',
                    },
                  ]">
                  <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
                </a-input-password>
              </a-form-item>

              <a-form-item style="margin: 0 8px 32px">
                <a-checkbox :checked="isKeep" @change="keepMe">
                  记住我
                </a-checkbox>
                <router-link style="float: right; color: #666" to="/forgot">忘记密码</router-link>
              </a-form-item>

              <a-form-item>
                <a-button type="primary" shape="round" size="large" block htmlType="submit" class="login-button">登
                  录</a-button>
              </a-form-item>
              <a-form-item>
                <a-button style="
                    border-color: #35ab61;
                    background: #fff;
                    color: #35ab61;
                  " type="primary" shape="round" size="large" block class="login-button" @click="register">注
                  册</a-button>
              </a-form-item>

              <!-- <div style="text-align:center;">
                还没账号？<a @click="register">立即注册</a>
              </div> -->
            </div>
          </a-form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { userLogin, getUserInfo, getStatus } from "@/api/api";
import { getFlag } from "@/api/common";
import { timeFix } from "@/utils/util";
import encrypt from "@/utils/encrypt";

export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      isKeep: false,
      timer: null,
    };
  },
  methods: {
    // 注册
    register() {
      getFlag({
        paramKey: "register",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.$router.push({ path: "/register" });
        } else {
          this.$message.error("暂未开启");
        }
      });
    },
    keepMe(e) {
      this.isKeep = e.target.checked;
      console.log(`checked = ${e.target.checked}`);
      localStorage.setItem("isKeep", this.isKeep);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          userLogin({
            schoolCode: localStorage.getItem("schoolCode"),
            type: localStorage.getItem("stage"),
            username: values.username.trim(),
            password: encrypt(values.password.trim(), values.username.trim()),
          }).then((res) => {
            if (res.code == 0) {
              if (this.isKeep) {
                localStorage.setItem("username", values.username);
                localStorage.setItem("password", values.password);
              }

              if (res.data == 2) {
                this.$router.push({ path: "/register", query: { currentStep: 1 } });
                return
              }

              this.getUserInfo();

              let redirectURI = this.$route.query.redirectURI;
              if (redirectURI) {
                window.location.href = decodeURIComponent(redirectURI);
              } else {
                this.$router.push({ path: "/" });
              }

              // 延迟 1 秒显示欢迎信息
              setTimeout(() => {
                this.$notification.success({
                  message: "欢迎",
                  description: `${timeFix()}，欢迎回来`,
                });
              }, 1000);
            } else {
              this.$message.error(res.description);
            }
          });
        }
      });
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        localStorage.setItem(`user`, JSON.stringify(res.data));
        localStorage.setItem(`userCode`, res.data.userCode);
        this.getStatus();
      });
    },
    getStatus() {
      getStatus().then((res) => {
        // regStatus  注册状态（1  待审核  2 注册成功 3退回）
        if (res.data.regStatus == 2) {
          this.$router.replace({
            path: "/home",
          });
        } else {
          this.$router.push({
            path: "/homeCheck",
            query: {
              regStatus: res.data.regStatus,
              regBz: res.data.regBz,
            },
          });
        }
      });
    },
  },

  mounted() {
    const isKeepPsd = localStorage.getItem("isKeep") || false;
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    this.isKeep = isKeepPsd;
    this.form.setFieldsValue({
      username: username && isKeepPsd ? username : "",
      password: password && isKeepPsd ? password : "",
    });
  },
};
</script>

<style lang="less" scoped>
.login {
  // background: url(~@/assets/user/bg.png) center bottom no-repeat;
  background: #f5f5f5;
  min-height: 100%;
  width: 100%;
  background-size: cover;
}

// /deep/ .ant-form-item{
//   margin-bottom: 12px;
// }
.wrap {
  width: 1000px;
  height: 560px;
  margin: 0 auto 24px;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  .con {
    display: flex;
  }

  .img {
    img {
      width: 480px;
    }
  }

  .user {
    width: 520px;
    padding: 72px 65px 88px;

    h3 {
      font-size: 30px;
      color: #333;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .line {
      width: 61px;
      height: 6px;
      background-color: #35ab61;
      border-radius: 4px;
      margin-bottom: 52px;
    }
  }
}

.cus-input {
  /deep/ .ant-input-lg {
    height: 48px;
  }
}

.ant-btn-primary {
  height: 48px;
  box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
}
</style>
