var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('Header'),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require('@/assets/user/login.png'),"alt":""}})]),_c('div',{staticClass:"user"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"container"},[_c('h3',[_vm._v("登录")]),_c('div',{staticClass:"line"}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入学生身份证号/报名号',
                    },
                  ],
                  validateTrigger: 'change',
                },
              ]),expression:"[\n                'username',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入学生身份证号/报名号',\n                    },\n                  ],\n                  validateTrigger: 'change',\n                },\n              ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"text","placeholder":"请输入学生身份证号/报名号"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-bottom":"8px"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                    validateTrigger: 'blur',
                  },
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '请输入密码' }],\n                    validateTrigger: 'blur',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin":"0 8px 32px"}},[_c('a-checkbox',{attrs:{"checked":_vm.isKeep},on:{"change":_vm.keepMe}},[_vm._v(" 记住我 ")]),_c('router-link',{staticStyle:{"float":"right","color":"#666"},attrs:{"to":"/forgot"}},[_vm._v("忘记密码")])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"type":"primary","shape":"round","size":"large","block":"","htmlType":"submit"}},[_vm._v("登 录")])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",staticStyle:{"border-color":"#35ab61","background":"#fff","color":"#35ab61"},attrs:{"type":"primary","shape":"round","size":"large","block":""},on:{"click":_vm.register}},[_vm._v("注 册")])],1)],1)])],1)])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }